<template>
  <div id="adduser" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <!-- <div class="N-Page T-size-36">User Profile</div> -->
        <div class="N-Page T-size-36">{{ title }}</div>
      </div>
    </div>
    <div id="userContent" class="wrap-Main" v-if="u_id != 0">
      <div class="box-S4 flex-between noPadding">
        <div class="box-S4">
          <div class="B-carddetail">
            <div class="panal-title">Display</div>
            <div class="b-row">
              <div class="b-display">
                <!-- <div class="B-display"></div> -->
                <div class="B-display" :style="{
                  backgroundImage:
                    'url(' + formModel.u_imageurl + ') !important',
                }"></div>
              </div>
            </div>
            <div class="b-row">
              <!-- <v-btn class="theme-btn-even btn-upload center w100"> -->
              <v-btn class="theme-btn-even btn-upload center" @click="dialogUpload = true">
                <span class="I-upload"></span>
                <span class="T-size-18">Upload Photo</span>
              </v-btn>
            </div>
          </div>
        </div>
        <!-- <div class="box-S2">
          <div class="B-carddetail">
            <div class="panal-title">Username</div>
            <div class="b-row">
              <v-text-field label="Username"></v-text-field>
            </div>
          </div>
          <div class="B-carddetail marginTop">
            <div class="panal-title">Password</div>
            <div class="b-row">
              <v-text-field label="Password"></v-text-field>
            </div>
            <div class="b-row">
              <v-text-field label="Confirm Password"></v-text-field>
            </div>
          </div>
        </div> -->
      </div>
      <div class="line marginTop"></div>
    </div>
    <div id="userInfo" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Staff Personal Information</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Staff Code" v-model="formModel.u_staffcode"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Title Name" v-model="formModel.u_tital"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="First Name" v-model="formModel.u_firstname" @keyup="UppercaseAndCheckDuplicateUser()"></v-text-field>
                <p v-show="duplicateData" style="color: red;"><b>Duplicate data</b></p>
              </div>
              <div class="box-S2">
                <v-text-field label="Last Name" v-model="formModel.u_lastname" @keyup="UppercaseAndCheckDuplicateUser()"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-select :items="itemsListGender" v-model="formModel.u_gender" class="hideMessage" label="Gender">
                </v-select>
              </div>
              <div class="box-S2">
                <v-text-field label="Nationality" v-model="formModel.u_national_id"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Blood Group" v-model="formModel.u_bloodgroup"></v-text-field>
              </div>
              <div class="box-S2">
                <v-select :items="itemsListCrewType" v-model="formModel.u_crewtype" class="hideMessage"
                  label="Crew Type"></v-select>
                <!-- <v-text-field
                  label="Crew Type"
                  v-model="formModel.u_crewtype"
                ></v-text-field> -->
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-select :items="itemsListOwner" item-text="o_name" item-value="o_id" v-model="formModel.customer"
                  class="hideMessage" label="Customer"></v-select>
              </div>
            </div>
            <!-- <div class="b-row">
              <div class="box-S4">
                <v-select
                  :items="itemsListRole"
                  item-text="r_name"
                  item-value="r_id"
                  v-model="formModel.role"
                  class="hideMessage"
                  label="Role"
                ></v-select>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Estimated Info.</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Weight (Kg.)" v-model="formModel.u_estimatedweight"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Height (Cm.)" v-model="formModel.u_estimatedtall"></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class="N-Page T-size-24">Contact Information</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="ID Card/Passport No." v-model="formModel.u_idcard_no"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Email" v-model="formModel.u_email"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Phone No." v-model="formModel.u_homephone"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Mobile No." v-model="formModel.u_telephone"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-textarea label="Address" v-model="formModel.u_address" hide-details rows="2"></v-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div id="userInfo" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Emergency Contact</div>
      </div>
      <div class="box-S4">
        <div class="B-carddetail B-formMain">
          <div class="b-row">
            <div class="box-S1-3">
              <v-text-field label="Name" v-model="formModel.u_emergency_contact"></v-text-field>
            </div>
            <div class="box-S1-3">
              <v-text-field label="Relation" v-model="formModel.u_emergency_relation"></v-text-field>
            </div>
            <div class="box-S1-3">
              <v-text-field label="Telephone No." v-model="formModel.u_emergency_telphone"></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>
    <div id="userInfo" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Career</div>
      </div>
      <div class="box-S4">
        <div class="B-carddetail B-formMain">
          <div class="b-row">
            <div class="box-S1-3">
              <v-select :items="itemsListCompany" item-text="c_name" item-value="c_id" v-model="formModel.company"
                class="hideMessage" label="Company"></v-select>
            </div>
            <div class="box-S1-3">
              <v-text-field label="Department" v-model="formModel.u_department"></v-text-field>
            </div>
            <div class="box-S1-3">
              <v-text-field label="Job Position" v-model="formModel.u_jobposition"></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>
    <div id="userInfo" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Certificate Expiration Date</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <!-- <div class="b-row">
              <div class="box-S2">
                <v-menu transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="input-date"
                      v-model="formModel.u_safety_training"
                      label="Safety Training"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-range"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formModel.u_safety_training"
                    :readonly="readonly_eng || readonly_pilot"
                    @input="menuselect_depart_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-menu transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="input-date"
                      v-model="formModel.u_medical_checkup"
                      label="Medical Check Up"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-range"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formModel.u_medical_checkup"
                    :readonly="readonly_eng || readonly_pilot"
                    @input="menuselect_depart_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div> -->

            <!-- Edit -->
            <div class="b-row">
              <div class="box-S2">
                <v-menu v-model="menuselectdatesafety" :close-on-content-click="false" :nudge-right="0" :nudge-top="10"
                  transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field class="input-date" append-icon="mdi-calendar-range"
                      v-model="formModel.u_safety_training" label="Safety Training" readonly v-on="on"></v-text-field>
                    <v-checkbox v-model="formModel.u_waive_safety_training" label="Waive Safety Training"></v-checkbox>
                  </template>
                  <v-date-picker v-model="formModel.u_safety_training" @input="menuselectdatesafety = false">
                  </v-date-picker>
                </v-menu>
              </div>

              <div class="box-S2">
                <v-menu v-model="menuselectdatemedical" :close-on-content-click="false" :nudge-right="0" :nudge-top="10"
                  transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field class="input-date" append-icon="mdi-calendar-range"
                      v-model="formModel.u_medical_checkup" label="Medical Check Up" readonly v-on="on"></v-text-field>
                    <v-checkbox v-model="formModel.u_waive_medical_checkup" label="Waive Medical Check Up"></v-checkbox>
                  </template>
                  <v-date-picker v-model="formModel.u_medical_checkup" @input="menuselectdatemedical = false">
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Remark</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Locker No." v-model="formModel.u_locker_no"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Remark" v-model="formModel.u_remark"></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="box-S4">
          <div class=" B-formMain" style="width: 100%;">
            <div class="b-row">
              <div class="box-S2">
              </div>
              <div class="box-S2" style="align-items: end;">
                <v-btn class="theme-btn-even Bsize150" @click="ViewHistory()">
                  <span class="T-size-18">View History</span>
                  <span class="I-arrrowR"></span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>

    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSave">
          <v-btn class="theme-btn-even btn-cancle Bsize150" @click="CancelAddOrEdit()">
            <span class="T-size-18">Cancel</span>
          </v-btn>
          <v-btn class="theme-btn-even btn-save Bsize150" @click="dialogSave = true">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
        </div>
        <!-- <div class="b-btnDelete" :v-if="mode != 'Add'">
          <v-btn
            class="theme-btn-even btn-delete Bsize150"
            @click="dialogDelete = true"
          >
            <span class="T-size-18">delete</span>
          </v-btn>
        </div> -->
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogSave" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm Save</div>
            <div class="t-des">Are you sure you want to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSave = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="AddOrEditUser()">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Complete</div>
            <div class="t-des">
              Save Data Complete
              <br />You want to Create Transport?
              <v-btn class="theme-btn-even btn-transport center Bsize190" @click="GotoCreateTransport()">
                <span class="I-transport"></span>
                <span class="T-size-18">Create Transport</span>
              </v-btn>
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="GotoListBooking()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog delete">
            <div class="t-title">Confirm Deletion</div>
            <div class="t-des">
              Are you sure you want to permanently delete this item?
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogDelete = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-delete Bsize100" text @click="DeleteBooking()">
              <span class="T-size-18">Delete</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUpload" persistent max-width="50%">
      <v-card id="dialog">
        <ImageBox ref="ImageBox" @success="UploadSuccess"></ImageBox>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDuplicate" persistent max-width="500">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog-for-cancel cencel">
            <div class="t-title">
              Cannot create duplicate data.<br />"{{ duplicateFullname }} [{{
                  duplicateCustomer
              }}]"<br />has already been in system.
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="dialogDuplicate = false">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { startOfDay, endOfDay, format } from "date-fns";
import { th } from "date-fns/locale";
import ImageBox from "../components/upload/ImageBox";

import fillerdate from "../utils/filterdate";

export default {
  name: "UserProfile",
  data: () => ({
    mode: "",
    itemsDataBooking: [],
    itemsDataLegs: [],
    itemsListAirport: [],
    itemsListBookingType: [],
    itemsListCompany: [],
    chooseItemLegs: null,
    chooseItemLegIndex: null,
    listAddLegs: [],
    activity1: false,
    activity2: false,
    activity3: false,
    activity4: false,

    menuselectdatesafety: false,
    menuselectdatemedical: false,

    menuselecttime: false,
    dialog: false,
    dialogEvent: false,
    dialogSave: false,
    dialogConfirm: false,
    loading: false,
    dialogDelete: false,
    dialogUpload: false,

    dialogDuplicate: false,
    duplicateFullname: "",
    duplicateCustomer: "",

    modeEvent: "",
    flightno: "",
    company: {},
    date: "",
    time: "",
    bookingtype: "",
    departurefrom: "",
    legs: "",
    idbook: "",
    ref_id: "",
    userdata: "",
    dateNow: new Date(),
    ////////////////REPORT///////////////
    ArrListPassenger: [],
    ArrPassLeg_Out: [],
    ArrPassLeg_In: [],

    itemsListGender: ["Male", "Female"],
    itemsListOwner: [],
    itemsListRole: [{ r_id: "", r_name: "" }],

    //itemsListCrewType: ["AUDITOR", "Drilling", "NON ORGANIC CREW", "FSO"],
    //Edit
    itemsListCrewType: ["ORGANIC CREW", "NON ORGANIC CREW"],
    //itemsListPosition: ["OPR. TECH.", "WS SUPV.", "OTL", "MUDA OIM"],

    u_id: 0,
    title: "",
    redirect: "",
    formModel: {
      u_gender: "Male",
      u_crewtype: "ORGANIC CREW",
    },

    duplicateData: false,
  }),
  props: [],
  components: {
    ImageBox,
  },
  mounted: function () {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.u_id = this.$route.query.u_id;
    this.title = this.$route.query.title;
    this.redirect = this.$route.query.redirect;
    this.renderUI();
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },

  methods: {
    async SelectListCompany() {
      try {
        const q = {};
        var res = await feathersClientUOA.service("company").find({ query: q });
        this.itemsListCompany = res.data;
        // this.formModel.company = {
        //   c_id: 1,
        //   c_name: "UOA",
        // };
        this.itemsListCompany.sort((a, b) => (a.c_name > b.c_name) ? 1 : -1)
        this.formModel.company = this.itemsListCompany[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListOwner() {
      try {
        const q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListOwner = res.data;

        this.formModel.customer = {
          o_id: this.userdata.o_id,
          o_name: this.userdata.o_name,
        };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListRole() {
      try {
        const q = {};
        if (this.userdata.r_id != 1) {
          q.r_id = 3;
        }
        var res = await feathersClientUOA.service("role").find({ query: q });
        this.itemsListRole = res.data;

        if (this.userdata.r_id != 1) {
          this.formModel.role = { r_id: 3, r_name: "Passenger" };
        } else {
          this.formModel.role = {
            r_id: this.userdata.r_id,
            r_name: this.userdata.r_name,
          };
        }
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async renderUI() {
      await this.SelectListOwner();
      await this.SelectListCompany();
      await this.SelectListRole();

      // this.formModel.u_gender = "Male";
      //this.formModel.u_crewtype = "ORGANIC CREW";

      try {
        if (this.u_id != 0) {
          // const { user } = await feathersClientUOA.get("authentication");
          this.search = "";
          const q = {};
          q.u_id = this.u_id;
          let res = await feathersClientUOA
            .service("viewuser")
            .find({ query: q });

          this.formModel = res.data[0];


          // this.formModel.customer = this.formModel.o_id;
          //Edit
          this.formModel.customer = {
            o_id: this.formModel.o_id,
            o_name: this.formModel.o_name,
          };

          if (this.formModel.c_id != null) {
            this.formModel.company = {
              c_id: this.formModel.c_id,
              c_name: this.formModel.c_name,
            };
          } else {
            //Set default
            this.formModel.company = {
              c_id: this.itemsListCompany[0].c_id,
              c_name: this.itemsListCompany[0].c_name,
            };
          }

          this.formModel.role = this.formModel.r_id;
          this.formModel.u_safety_training = format(
            new Date(this.formModel.u_safety_training),
            "yyyy-MM-dd",
            { locale: th }
          );
          this.formModel.u_medical_checkup = format(
            new Date(this.formModel.u_medical_checkup),
            "yyyy-MM-dd",
            { locale: th }
          );
        }
      } catch (err) { }
    },

    //by big
    async AddOrEditUser() {
      if (this.u_id != 0) {
        this.mode = "edit";
      } else {
        this.mode = "add";
      }

      this.saveToServer();
    },

    async saveToServer() {
      //Map
      if (this.formModel.customer.o_id != undefined) {
        this.formModel.o_id = this.formModel.customer.o_id;
      } else {
        this.formModel.o_id = this.formModel.customer;
      }

      this.formModel.u_emergency_telephone = this.formModel.u_emergency_telphone;
      if (this.formModel.company.c_id != undefined) {
        this.formModel.c_id = this.formModel.company.c_id;
      } else {
        this.formModel.c_id = this.formModel.company;
      }

      delete this.formModel.FullName;
      delete this.formModel.MedicalCheckupDayLeft;
      delete this.formModel.SafetyTrainingDayLeft;
      delete this.formModel.ShortName;
      delete this.formModel.CodeName;
      delete this.formModel.company;
      delete this.formModel.r_name;
      delete this.formModel.c_name;
      delete this.formModel.o_name;
      delete this.formModel.u_emergency_telphone;
      delete this.formModel.customer;

      this.loading = true;

      if (this.mode === "edit") {
        //Edit Passenger
        try {
          console.log(JSON.stringify(this.formModel));
          await await feathersClientUOA
            .service("user")
            .patch(this.formModel.u_id, this.formModel);
          this.dialogSave = false;
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
          this.$router.push({ path: this.redirect, query: {} });
        }
      } else {
        //Add New Passenger
        let result = await this.CheckDuplicateUser();
        this.duplicateFullname = result.DuplicateFullname;
        this.duplicateCustomer = result.DuplicateCustomer;

        if (!result.Status) {
          this.formModel.u_imageurl = "/img/user.png";
          // this.formModel.r_id = this.formModel.role.r_id;
          this.formModel.r_id = 3;
          delete this.formModel.role;
          // this.formModel.c_id = this.formModel.company.c_id;
          this.formModel.cb = this.userdata.u_firstname;

          try {
            await feathersClientUOA.service("user").create(this.formModel);
          } catch (err) {
            console.log(err);
          } finally {
            this.loading = false;
            this.$router.push({ path: this.redirect, query: {} });
          }
        } else {
          this.dialogSave = false;
          this.dialogDuplicate = true;
        }
      }
    },
    ViewHistory() {
      this.$router.push({
        path: "/passengerhistory",
        query: {
          u_id:  this.u_id,
          redirect: "/userprofile",
        },
      });
    },
    UploadSuccess(uploadInfo) {
      // let url = "/uploads/" + uploadInfo.filename;
      let url = uploadInfo.url;
      this.UpdateImageUrl(url);
      this.dialogUpload = false;
      this.formModel.u_imageurl = url;
      this.renderUI();
    },

    async UpdateImageUrl(url) {
      try {
        let dataEdit = {
          u_imageurl: url,
        };
        await feathersClientUOA
          .service("account")
          .patch(this.formModel.u_id, dataEdit);
      } catch (err) {
        console.log(err);
      } finally {
      }
    },
    async DeleteBooking() { },
    CancelAddOrEdit() {
      this.$router.push({ path: this.redirect, query: {} });
    },
    async CheckDuplicateUser() {
      this.duplicateFullname = "";
      this.duplicateCustomer = "";

      let result = {
        Status: false,
        DuplicateFullname: "",
        DuplicateCustomer: "",
      };

      const q = {};
      q.u_firstname = this.formModel.u_firstname;
      q.u_lastname = this.formModel.u_lastname;
      q.o_id = this.formModel.o_id;
      q.$eager = "[customer]";

      let res = await feathersClientUOA.service("user").find({ query: q });

      let data = res.data[0];

      if (res.total > 0) {
        result.Status = true;
        result.DuplicateFullname = data.u_firstname + " " + data.u_lastname;
        result.DuplicateCustomer = data.customer.o_name;
      }

      return result;
    },

    async UppercaseAndCheckDuplicateUser() {
      this.formModel.u_firstname = this.formModel.u_firstname.toUpperCase();
      this.formModel.u_lastname = this.formModel.u_lastname.toUpperCase();

      this.duplicateFullname = "";
      this.duplicateCustomer = "";

      let result = {
        Status: false,
        DuplicateFullname: "",
        DuplicateCustomer: "",
      };

      const q = {};
      q.u_firstname = this.formModel.u_firstname;
      q.u_lastname = this.formModel.u_lastname;
      q.o_id = this.formModel.customer.o_id;
      q.$eager = "[customer]";
      console.log(q)
      let res = await feathersClientUOA.service("user").find({ query: q });
      console.log(res)
      let data = res.data[0];

      if (res.total > 0) {
        result.Status = true;
        result.DuplicateFullname = data.u_firstname + " " + data.u_lastname;
        result.DuplicateCustomer = data.customer.o_name;

        this.duplicateData =  true;
      }else{
        this.duplicateData =  false;
      }
    },
    Back() {
      this.$router.go(-1);
    },
  },
};
</script>

